<template>
    <div>
        <div class="text-center">
            <h2>Theodora Ntanasi</h2>
        </div>
        <v-container class="main-text text-container">
            <div>
                <v-img :width="415" :height="415" style="float: left;" class="bradius" :src="require('@/assets/partners/Photo-Theodora_Ntanasi.jpg')" />
                <p class="cv-text">
                    Theodora Ntanasi is currently a PhD candidate at the Laboratory of Vegetable Production, Department of Crop Science, Agricultural University of Athens, under the supervision of Assistant Professor Dr. Georgia Ntatsi. Her Ph.D. research focuses on assessing landraces of solanum vegetables from the Mediterranean basin, examining their yield, quality traits, nutritional composition and metabolism when cultivated in soilless culture under saline stress. She awarded her agronomist degree in Crop Science from the Agricultural University of Athens in 2019. Since 2020, she has been actively involved in several research projects, including two European (VEG-ADAPT and RADIANT), five national (NUTRISENSE, VF2FARM, OPTIMUS, VIOPROSARMOGI, DSS-KIPEFTIKON) and one with Yara International. Her research interests include plant nutrition, product quality, landraces’ evaluation and metabolism of fruit vegetables. Her authorship consists of 9 published papers in international peer-reviewed scientific journals with an Impact Factor according to the Journal Citation Reports, as well as 6 scientific papers in full proceedings of international conferences and congresses.                </p>
            </div>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.cv-card {
    border: 1px solid #D9D9D9;
    border-radius: 25px;
    text-align: center;
}
.bradius {
    border-radius: 25px;
    margin-right: 15px;
}
.cv-text {
    display: inline;
}
.main-text {
    width: 60vw;
    margin: auto;
    color: #646464;
}
</style>
